var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"logo-wrapper",class:{
    'is-smaller': _vm.smaller,
  }},[_c('div',{staticClass:"d-flex mb-1"},[(_setup.logo)?_c('img',{staticClass:"d-inline-flex flex-grow-0 mr-2",attrs:{"width":"20","height":"20","src":`data:image/png;base64,${_setup.logo}`,"alt":"Logo"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"flex-grow-1 simple-model-header-symbol",class:{
        'is-smaller': _vm.smaller,
      }},[_vm._v("\n      "+_vm._s(_setup.tickerSymbol)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"d-block text-center simple-model-header-companyname",class:{
      'is-smaller': _vm.smaller,
    }},[_vm._v("\n    "+_vm._s(_setup.companyName)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }