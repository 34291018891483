import { getLogo } from "~/utils/tools/logos";
export default {
  __name: 'SimpleCompanyMeta',
  props: {
    smaller: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(__props) {
    var $route = useRoute();
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var routeCid = computed(function () {
      return $route.query.cid;
    });
    var tickerSymbol = computed(function () {
      var _$store$state$ciq$tic;
      return (_$store$state$ciq$tic = $store.state.ciq.ticker) === null || _$store$state$ciq$tic === void 0 ? void 0 : _$store$state$ciq$tic.tickersymbol;
    });
    var companyName = computed(function () {
      var _$store$state$ciq$tic2;
      return (_$store$state$ciq$tic2 = $store.state.ciq.ticker) === null || _$store$state$ciq$tic2 === void 0 ? void 0 : _$store$state$ciq$tic2.companyname;
    });
    var logo = computed(function () {
      var logoObj = getLogo(routeCid.value);
      if (logoObj !== null && logoObj !== void 0 && logoObj.data && !logoObj.hideLogo) {
        return logoObj.data;
      }
      return "";
    });
    return {
      __sfc: true,
      $route: $route,
      $store: $store,
      routeCid: routeCid,
      tickerSymbol: tickerSymbol,
      companyName: companyName,
      logo: logo
    };
  }
};